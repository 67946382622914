import logo from './logo.svg';
import './styles/sass/App.scss';


import {React, Suspense, lazy, useState, useEffect} from "react"

import { Route, Routes, BrowserRouter as Router, Navigate, useParams, useNavigate} from "react-router-dom";

import { Position, Toast, Toaster, Classes} from "@blueprintjs/core";

import firebase from "./utils/firebaseSetUp";

import PageLoading from './components/loaders/PageLoading';

import $ from "jquery";

import ConsentScreen from './components/ConsentScreen';

const Loading = <PageLoading/>



const MainPage = lazy(() => import("./components/MainPage"))
const Login = lazy(() => import("./components/Login"));
const VideoPage = lazy(() => import("./components/VideoPage"));
const SignUp = lazy(() => import("./components/SignUp"));
const PrivacyPolicy = lazy(() => import("./components/Privacy"));
const TermsAndConditions = lazy(() => import("./components/Terms"));
const Declaration2257 = lazy(() => import("./components/Declaration2257"));
const Episodes = lazy(() => import("./components/Episodes"));
const Favorites = lazy(() => import("./components/Favorites"));
const Threesome = lazy(() => import("./components/Threesome"));
const Friends = lazy(() => import("./components/Friends"));

//Admin components
const Auth = lazy(() => import("./components/admin/Auth"));
const Resume = lazy(() => import("./components/admin/Resume"));
const SideNav = lazy(() => import("./components/admin/SideNav"));
const UsersManagement = lazy(() => import("./components/admin/UsersManagement"));
const EditUser = lazy(() => import("./components/admin/EditUser"));
const AddVideo = lazy(() => import("./components/admin/AddVideo"));
const Videos = lazy(() => import("./components/admin/Videos"));
const Slider = lazy(() => import("./components/admin/Slider"));
const Trailers = lazy(() => import("./components/admin/Trailers"));
const Privacy = lazy(() => import("./components/admin/Privacy"));
const Terms = lazy(() => import("./components/admin/Terms"));
const Info2257 = lazy(() => import("./components/admin/Info2257"));
const EditVideo = lazy(() => import("./components/admin/EditVideo"));
const EditTrailer = lazy(() => import("./components/admin/EditTrailer"));
const Categories = lazy(() => import("./components/admin/Categories"));

function App() {

  let [auth, setAuth] = useState(false);
  let [admin, setAdmin] = useState(false);
  let [userData, setUserData] = useState(null);
  let [toasts, setToasts] = useState([]);

  const [showConsent, setShowConsent] = useState(true);

  const [size, setSize] = useState("small")

  let toaster = {};
  let refHandlers = {
      toaster:(ref) => {toaster = ref},
  }




  const addToast = (message) => {
    toaster.show({ message: message});
}
  useEffect(() => {
    const cookieStorage = {
      getItem: (item) => {
          const cookies = document.cookie
              .split(';')
              .map(cookie => cookie.split('='))
              .reduce((acc, [key, value]) => ({ ...acc, [key.trim()]: value }), {});
          return cookies[item];
      },
      setItem: (item, value) => {
          document.cookie = `${item}=${value};`
      }
  }

    const storageType = cookieStorage;
  const consentPropertyName = 'jdc_consent';
  const shouldShowPopup = () => !storageType.getItem(consentPropertyName);
  const saveToStorage = () => storageType.setItem(consentPropertyName, true);
  
  window.onload = () => {
  
      const acceptFn = event => {
          saveToStorage(storageType);
          //consentPopup.classList.add('hidden');
          setShowConsent(false)
      }
      const consentPopup = document.getElementById('consent-popup');
      const acceptBtn = document.getElementById('accept');
      acceptBtn.addEventListener('click', acceptFn);

      console.log(acceptBtn);
  
      if (shouldShowPopup(storageType)) {
        
        /*
          setTimeout(() => {
              consentPopup.classList.remove('hidden');
          }, 2000);
          */
      }else {
        setShowConsent(false)
      }
  
  };

    function myFunction(x) {
      if (x.matches) { // If media query matches
        setSize("small")
      
      } else {
        setSize("large");
   
         }
    }
    
    var x = window.matchMedia("(max-width: 800px)")
    myFunction(x) // Call listener function at run time
    x.addListener(myFunction) // Attach listener function on state changes

    firebase.auth().onAuthStateChanged(u => {
      if(u){

        firebase.firestore().collection("users").doc(u.uid).get()
        .then(doc => {

          let data =doc.data();
          data.id = doc.id;

          if(data.state === "active"){
          setAuth(true);

          

          setUserData(data);
          if(data.type === "admin"){
            setAdmin(true)
           }
           getUser(doc.id);

           $.ajax({
             url:" https://str8-lord.ew.r.appspot.com/routineCheck",
             method:"POST"
           })
           .then(() => {
             console.log("Routine check done!")
           })
           .catch(e => {
             console.log(e);
             addToast("Algo salió mal")
           })

          }else {
            firebase.auth().signOut();
            addToast("Acceso denegado");

            if(userListener !== undefined){
              userListener()// Clean up the listener
            }
          }

          
        })
        .catch(e => {
          console.log(e);
          
          firebase.auth().signOut();
        })
      }else {

        setAdmin(false);
        setAuth(false);
        setUserData(null)
      }
    });

  }, [])

  let userListener = undefined;

  const getUser = (id) => {
    userListener = firebase.firestore().collection("users").doc(id).onSnapshot(doc => {
      let  data =doc.data();
      data.id = doc.id;

      if(doc.data().state === "active"){
        setUserData(data);
      }else {
        firebase.auth().signOut()
      }
      
    })
  }

  return (
    <div className="App">
      {showConsent?<ConsentScreen/>:null}
       <Toaster className={Classes.OVERLAY} position={Position.TOP} ref={refHandlers.toaster}>
                    {/* "Toasted!" will appear here after clicking button. */}
                    {toasts.map(toast => <Toast action={{onClick:() => {}, text:"Resend"}} {...toast} />)}
                </Toaster>
        <Suspense fallback={Loading}>
          <Router>
            {auth && admin && window.location.pathname.includes("admin")?<SideNav/>:null}
            <Routes>
            
              <Route path="/" element={<MainPage size={size} addToast={addToast} userData={userData}/>}/>
              <Route path="/login" element={<Login size={size} addToast={addToast} userData={userData}/>}/>
              <Route path="/video/:id" element={<VideoPage size={size} addToast={addToast} userData={userData}/>}/>
              <Route path="/trailer/:id" element={<VideoPage size={size} type="trailer" addToast={addToast} userData={userData}/>}/>
              <Route path="/join-new" element={<SignUp size={size} addToast={addToast} userData={userData}/>}/>
              <Route path='/privacy' element={<PrivacyPolicy size={size} addToast={addToast} userData={userData}/>}/>
              <Route path='/terms' element={<TermsAndConditions size={size} addToast={addToast} userData={userData}/>}/>
              <Route path='/info2257' element={<Declaration2257 size={size} addToast={addToast} userData={userData}/>}/>
              <Route path='/episodes' element={<Episodes size={size} addToast={addToast} userData={userData}/>}/>
              <Route path='/threesome' element={<Threesome size={size} addToast={addToast} userData={userData}/>}/>
              <Route path='/friends' element={<Friends size={size} addToast={addToast} userData={userData}/>}/>
              <Route path='/favorites' element={<Favorites size={size} addToast={addToast} userData={userData}/>}/>
              

              <Route path="/admin/login" element={(auth == false || admin == false)?<Auth addToast={addToast}/>:<Navigate to={"/admin"}/>}/>
              <Route path="/admin" element={auth && admin?<Resume size={size} userData={userData} addToast={addToast}/>:<Navigate to={"/admin/login"}/>}/>
              <Route path="/admin/user" element={auth && admin?<UsersManagement size={size} userData={userData} addToast={addToast}/>:<Navigate to={"/admin/login"}/>}/>
              <Route path="/admin/user/:id"   element={auth && admin?<EditUser size={size} userData={userData} addToast={addToast}/>:<Navigate to={"/admin/login"}/>}/>
              <Route path="/admin/video/new" element={auth && admin?<AddVideo size={size} userData={userData} addToast={addToast}/>:<Navigate to="/admin/login"/>}/>
              <Route path="/admin/video" element={auth && admin?<Videos size={size} userData={userData} addToast={addToast}/>:<Navigate to={"/admin/login"}/>}/>
              <Route path='/admin/video/edit/:id' element={auth && admin?<EditVideo size={size} userData={userData} addToast={addToast}/>:<Navigate to="/admin/login"/>}/>
              <Route path="/admin/home/slider" element={auth && admin? <Slider size={size} userData={userData} addToast={addToast}/>:<Navigate to={"/admin/login"}/>}/>
              <Route path="/admin/home/trailers" element={auth && admin?<Trailers size={size} userData={userData} addToast={addToast}/>:<Navigate to="/admin/login"/>}/>
              <Route path='/admin/home/trailer/edit/:id' element={auth && admin?<EditTrailer size={size} userData={userData} addToast={addToast}/>:<Navigate to={"/admin/login"}/>}/>
              <Route path='/admin/legal/privacy' element={auth && admin?<Privacy size={size} userData={userData} addToast={addToast}/>:<Navigate to={"/admin/login"}/>}/>
              <Route path='/admin/legal/terms' element={auth && admin?<Terms size={size} userData={userData} addToast={addToast}/>:<Navigate to={"/admin/login"}/>}/>
              <Route path='/admin/legal/info2257' element={auth && admin?<Info2257 size={size} userData={userData} addToast={addToast}/>:<Navigate to={"/admin/login"}/>}/>
              <Route path='/admin/categories' element={auth && admin?<Categories size={size} userData={userData} addToast={addToast}/>:<Navigate  to={"/admin/login"}/>}/>
          
            </Routes>
          </Router>
        </Suspense>
    </div>
  );
}

export default App;

import React, {useEffect} from "react";

export default function ConsentScreen(props){

    useEffect(() => {
        window.document.body.style.overflow = "hidden";

        return () => {
            window.document.body.style.overflow = "visible"
        }
    },[])

    return <div className="consent-screen-wrapper">

        <div className="consent-screen">
            <h1 className="text-center">WARNING ADULT CONTENT AHEAD</h1>
            <h1 className="text-center">YOU MUST BE OVER 18 TO ENTER</h1>

            <div className="mt-3 flex-center">
                <button className="btn btn-dark" id="accept" >
                    ENTER
                </button>

                <button className="btn btn-dark ml-3" onClick={() => {
                    window.close();
                }}>
                    LEAVE
                </button>
            </div>
        </div>
    </div>
}